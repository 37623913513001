import { Link } from 'react-router-dom';
import arrow from '../assets/arrowBack.svg';
 
export const GoBack = () => {
    return(
        <Link to="/categorias" className="go-back">
            <img src={arrow} alt='' />
            <p>Volver a categorías</p>
        </Link>
    )
}