import '../styles/footer.scss';
import logoWhite from '../assets/logo-white.svg';
import link from '../assets/link.svg';
import insta from '../assets/insta.svg';
import face from '../assets/face.svg';
// @ts-ignore
import { HashLink as Link } from 'react-router-hash-link';

export const Footer = () => {
	return <div className='footer'>
		<div className='inner-footer'>
			<div className='footer-container'>
				<div className='left-footer'>
					<div className='logo-footer'>
						<Link to='/' ><img src={logoWhite} alt="" /></Link>
					</div>
					<div className='links-footer'>
						<Link to="/categorias" className='link'><p>Productos</p></Link>
						<Link to="/#nosotros" smooth className='link'><p>Nosotros</p></Link>
						<Link to="/#clientes" smooth className='link'><p>Clientes</p></Link>
						<Link to="/#contacto" smooth className='link'><p>Contactos</p></Link>
					</div>
				</div>
				<div className='right-footer'>
					<Link to="/" target='_blank' className='link-r'><img src={link} alt="" /></Link>
					<Link to="https://www.instagram.com/enpaplasticos/" target='_blank' className='link-r'><img src={insta} alt="" /></Link>
					<Link to="https://www.facebook.com/enpaplasticos" target='_blank' className='link-r'><img src={face} alt="" /></Link>
				</div>
			</div>
			<div className='rights'>
				<p>EN.P.A. Envases Plásticos Atermicos SAICIFA © 2023</p>
			</div>
		</div>
	</div>
}