import recpientes from '../assets/categories/RecipienteResiduos.jpg';
import verticales from '../assets/categories/TanqueVertical.jpg';
import horizontales from '../assets/categories/TanquesHorizontales.jpg';
import metalica from '../assets/categories/EstructuraMetalica.jpg';
import rectangurales from '../assets/categories/Rectangulares.jpg';
import cilindricos from '../assets/categories/Cilindricos.jpg';
import bandeja from '../assets/categories/Bandeja.jpg';
import vial from '../assets/categories/Vial.jpg';
import caja from '../assets/categories/Caja.jpg';
import pallets from '../assets/categories/Pallete.jpg';
import juegos from '../assets/categories/Juegos.jpg';
import bebe from '../assets/categories/bebe.jpg';
import nautica from '../assets/categories/nautica.jpg';


export const categoriesList = [
    {
        categoryName: "Recipientes para residuos",
        categoryImg: recpientes,
        top: true
    },
    {
        categoryName: "Tanques Verticales",
        categoryImg: verticales
    },
    {
        categoryName: "Tanques Horizontales",
        categoryImg: horizontales,
        top: true
    },
    {
        categoryName: "Contenedores con estructura metálica",
        categoryImg: metalica,
        top: true
    },
    {
        categoryName: "Recipientes Rectangulares",
        categoryImg: rectangurales
    },
    {
        categoryName: "Recipientes Cilíndricos",
        categoryImg: cilindricos,
        top: true
    },
    {
        categoryName: "Bandejas",
        categoryImg: bandeja
    },
    {
        categoryName: "Seguridad Vial",
        categoryImg: vial
    },
    {
        categoryName: "Cajas Multiuso",
        categoryImg: caja
    },
    {
        categoryName: "Pallets Plásticos",
        categoryImg: pallets
    },
    {
        categoryName: "Piezas para juegos",
        categoryImg: juegos
    },
    {
        categoryName: "Cambiador Bebé",
        categoryImg: bebe
    },
    {
        categoryName: "Náutica",
        categoryImg: nautica
    }
]