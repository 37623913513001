import '../../styles/contact.scss';

export const Form = () => {
    return (
        <div className='form-container form-contact'>
        <form action="/contacto.php" method="post">
        <div className='input'>
         <label>Nombre:</label>
         <input type="text" id="nombre" name="nombre" required />
       </div>
       <div className='input'>
         <label>Empresa:</label>
         <input type="text" id="empresa" name="empresa" />
       </div>
       <div className='input'>
         <label>Email:</label>
         <input type="email" name="email" id="email" required />
       </div>
       <div className='input'>
         <label>Producto:</label>
         <input type="text" id="producto" name="producto" required />
       </div>
       <div className='input'>
         <label>Mensaje:</label>
         <textarea id="message" name="message" required></textarea>
       </div>
       <button type="submit" className='general-button '>Enviar</button>
       </form>
       </div>
    )
}