import '../../styles/contact.scss';
import { Form } from '../../components/contact/Form';

export const Contact = () => {
      return (
        <div className='contact'>
            <div id="contacto"></div>
          <div className='inner-contact'>
            <div className='form-container'>
               <h2>Contacto</h2>
              <Form />
            </div>
            <div className='contact-data'>
              <div className='data-contaimer'>
                <div className='data'>
                  <h3>Dirección:</h3>
                  <p>Dr. Ignacio Arieta 3875</p>
                  <p>B1754AQQ San Justo, Buenos Aires</p>
                </div>
                <div className='data'>
                  <h3>Teléfonos:</h3>
                  <p>4651-1491</p>
                  <p>4441-8001 / 8975</p>
                  <p>4482-3886</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};
