import '../styles/clients.scss';
import unilever from '../assets/Unilever.svg';
import ibm from '../assets/Ibm.svg';
import shell from '../assets/Shell.svg';
import renault from '../assets/Renault.svg';
import ford from '../assets/Ford.svg';
import esso from '../assets/Esso.svg';
import bosch from '../assets/Bosch.svg';

export const Clients = () => {
    return <div className='clients'>
        <div id="clientes"></div>
        <div className='inner-clients'>
            <h3>Trabajamos con algunas de las  empresas <b>líderes</b> del mundo.</h3>
            <div className='logos-grid'>
                <div className='logo'>
                    <img src={unilever} alt="" />
                </div>
                <div className='logo'>
                    <img src={ibm} alt="" />
                </div>
                <div className='logo'>
                    <img src={shell} alt="" />
                </div>
                <div className='logo'>
                    <img src={renault} alt="" />
                </div>
                <div className='logo'>
                    <img src={ford} alt="" />
                </div>
                <div className='logo'>
                    <img src={esso} alt="" />
                </div>
                <div className='logo'>
                    <img src={bosch} alt="" />
                </div>
            </div>
        </div>
    </div>
}