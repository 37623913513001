import '../styles/about.scss';
import about from '../assets/about-img.png';

export const About = () => {
    return <div className='about'>
         <div id="nosotros"></div>
        <div className='inner-about'>
            <div className='about-text'>
                <h2>Exelencia en plásticos, innovación y sustentabilidad.</h2>
                <p>Somos líderes en la industria del plástico, ofreciendo <span>soluciones innovadoras y de alta calidad.</span> Con tecnología de vanguardia y un equipo altamente capacitado.</p>
            </div>
            <div className='about-img'>
                <img src={about} alt='' />
            </div>
        </div>
    </div>
}