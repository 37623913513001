import React from 'react'
import '../styles/whatsapp.scss';
import globe from '../assets/whatsapp.svg';

export const Whatsapp = () => {
  return (
    <div className="whatsapp">
    <a href='https://wa.me/5491131566492' target="_blank" rel="noopener noreferrer">
        <img src={globe} alt="" />
        <p>Contactanos</p>
    </a>
  </div>
  )
}
