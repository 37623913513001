import '../styles/navBar.scss';
import Hamburger from 'hamburger-react'
import { useState } from 'react';
import logo from '../assets/logo-main.svg';
// @ts-ignore
import { HashLink as Link } from 'react-router-hash-link';

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const setOpen = () => setIsOpen(!isOpen)

  const handleLinkClick = () => {
    setIsOpen(false);
  };
  
  return (
    <div className='header'>
      <div className='topBar-box'>
        <p className="topBar"><span>Atención al cliente:</span> lunes a viernes de 8h a 17h.</p>
      </div>
      <div className='inner-header'>
        <div className='brand'>
          <Link to='/' ><img src={logo} alt='' /></Link>
        </div>
        <div className='hamburguer'>
          <Hamburger 
            toggled={isOpen} 
            toggle={setOpen} 
          />
        </div>
        <div className={`navbar ${isOpen ? "show-navbar" : ''}`}>
          <Link to='/categorias' className='link' onClick={handleLinkClick}>Productos</Link>
          <Link to='/#nosotros' smooth className='link' onClick={handleLinkClick}>Nosotros</Link>
          <Link to='/#clientes' smooth className='link' onClick={handleLinkClick}>Clientes</Link>
          <Link to='/#contacto' smooth className='link' onClick={handleLinkClick}>Contacto</Link>
        </div>
      </div>
    </div>
  )
}
