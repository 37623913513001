import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { Products } from '../pages/Products';
import { Detail } from '../pages/Detail';
import { Categories } from '../pages/Categories';

export const Main = () => {
  return (
    <Routes>
      <Route index path='/' element={<Home/>}></Route>
      <Route path="productos/:category" element={<Products/>}></Route>
      <Route path="detalle/:productId" element={<Detail/>}></Route>
      <Route path="categorias" element={<Categories/>}></Route>
    </Routes>
  )
}
