import '../styles/home.scss';
import { NavBar } from '../components/NavBar';
import { Cover } from '../components/Cover';
import { ProductsHome } from '../components/ProductsHome';
import { About } from '../components/About';
import { Clients } from '../components/Clients';
import { Contact } from '../components/contact/Contact';
import { Footer } from '../components/Footer';
import { Whatsapp } from '../components/Whatsapp';

export const Home = () => {
    window.scrollTo(0,0);
    
    return <div>
       <NavBar />
       <Cover />
       <Whatsapp />
       <ProductsHome />
       <About />
       <Clients />
       <Contact />
       <Footer />
    </div>
}