import '../../styles/products.scss';
import { Link } from 'react-router-dom';

export const ProductCard = (props: any) => {
    return <div className='card-container'> 
      <Link  to={`/detalle/${props.productId}`} className='card'>
      <div className='card-img'><img src={props.productcImg} alt="" /></div>
      <div className='card-title'>
        <p>{props.productName}</p>
      </div>
    </Link>
  </div>
  }
  