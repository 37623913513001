import { Link } from 'react-router-dom';
import '../styles/cover.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import enpa1 from '../assets/enpa-1.jpg';
import enpa2 from '../assets/enpa-2.jpg';
import enpa3 from '../assets/enpa-3.jpg';
// import enpa4 from '../assets/enpa-4.jpg';
// import enpa5 from '../assets/enpa-5.jpg';
import enpa6 from '../assets/enpa-5.jpg';
//import { useMediaQuery } from 'react-responsive';

export const Cover = () => {
  

  //const isDesktop = useMediaQuery({ minWidth: 768 });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const slidesData = [
    { imageUrl: enpa1 },
    { imageUrl: enpa2 },
    { imageUrl: enpa3 },
    { imageUrl: enpa6 },
  ];

  return (
    <Slider {...settings}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <div className="slide" style={{ backgroundImage: `url(${slide.imageUrl})` }}>
            <div className="slide-content">
              <div className='call-to-action'>
              <h1>Soluciones plásticas sustentables</h1>
              <p>Explora nuestro extenso catálogo y  encuentra productos <br className='salto'/> de calidad para todas tus necesidades.</p>
              <div className='btn-container'>
                  <Link to='/categorias' className='general-button'>Ver productos</Link>
              </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Cover;
