import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main';
import './styles/global.scss';
import { useWBSuite } from '@wbsuite/react-hooks';
import { Whatsapp } from './components/Whatsapp';

function App() {
  useWBSuite({ apiKey: 'mEuO+I8po0MreSjApcT213g/ezxv9+jP' });
  return (
    <Router>
      <Main />
      <Whatsapp />
    </Router>
  );
}

export default App;
